import { toast } from "react-toastify";
import {
    HiCheckCircle,
    HiInformationCircle,
    HiExclamation,
    HiXCircle,
} from "react-icons/hi";
import { __ } from "@wordpress/i18n";

export const fireNotify = (message, type = "", position = "bottom-right") => {
    switch (type) {
        case "error":
            return toast.error(
                <div className="hive_lite_toasts">
                    <div className="hive_lite_tosats_icon">
                        <span className="text-2xl text-red-400">
                            <HiXCircle />
                        </span>
                        <span className="toast-type">
                            {__("Error", "hive-support")}
                        </span>
                    </div>
                    <p className="hive_lite_toasts_message">{message}</p>
                </div>,
                {
                    position,
                    hideProgressBar: true,
                }
            );
        case "info":
            return toast.info(
                <div className="hive_lite_toasts">
                    <div className="hive_lite_tosats_icon">
                        <span className="text-2xl text-blue-400">
                            <HiInformationCircle />
                        </span>
                        <span className="toast-type">
                            {__("Info", "hive-support")}
                        </span>
                    </div>
                    <p className="hive_lite_toasts_message">{message}</p>
                </div>,
                {
                    position,

                    hideProgressBar: true,
                }
            );
        case "warning":
            return toast.warning(
                <div className="hive_lite_toasts">
                    <div className="hive_lite_tosats_icon">
                        <span className="text-2xl text-yellow-400">
                            <HiExclamation />
                        </span>
                        <span className="toast-type">
                            {__("Warning", "hive-support")}
                        </span>
                    </div>
                    <p className="hive_lite_toasts_message">{message}</p>
                </div>,
                {
                    position,
                    hideProgressBar: true,
                }
            );
        default:
            return toast.success(
                <div className="hive_lite_toasts">
                    <div className="hive_lite_tosats_icon">
                        <span className="text-2xl text-emerald-400">
                            <HiCheckCircle />
                        </span>
                        <span className="toast-type">
                            {__("Success", "hive-support")}
                        </span>
                    </div>
                    <p className="hive_lite_toasts_message">{message}</p>
                </div>,
                {
                    position,
                    hideProgressBar: true,
                }
            );
    }
};
