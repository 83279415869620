import moment from "moment";
import React, { useRef, useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { fireNotify } from "../../utils/utils";
import { __ } from "@wordpress/i18n";

let hsAjaxSync = true;
// hsAjaxSync = false;
let hsSyncingTimer = 4 * 500;
// hsSyncingTimer =500;
let hiveMessageCount = 0;

let hiveUserReplyCount = 0;

let GetAiInit = 1;

let HsSelectedHelpArticles = [];

const ChatBox = () => {
    let homeTabSettings = {};
    if (hive_lite_front.home_tab) {
        const cleanedString = hive_lite_front.home_tab.replace(/\\+/g, "");
        const settingsObject = JSON.parse(cleanedString);
        homeTabSettings = settingsObject;
    }

    if (homeTabSettings && homeTabSettings.enable_chat_widget == false) return;

    let enableAiChat = true;
    let enableMessaging = true;
    let enableHelp = true;

    let messagingTabSettings = {};
    if (hive_lite_front.messaging_tab) {
        const cleanedString = hive_lite_front.messaging_tab.replace(/\\+/g, "");
        const settingsObject = JSON.parse(cleanedString);
        messagingTabSettings = settingsObject;
        if (messagingTabSettings.enable_live_messaging == false)
            enableMessaging = false;
    }

    let helpTabSettings = {};
    if (hive_lite_front.help_tab) {
        const cleanedString = hive_lite_front.help_tab.replace(/\\+/g, "");
        const settingsObject = JSON.parse(cleanedString);
        helpTabSettings = settingsObject;
        if (helpTabSettings.enable_help_tab == false) enableHelp = false;
    }

    let aiTabSettings = {};
    if (hive_lite_front.ai_tab) {
        aiTabSettings = hive_lite_front.ai_tab;
        if (aiTabSettings.enable_ai_chat == false) enableAiChat = false;
    }

    // Disable chat window for admins and agents

    const defaultAvatar = hive_lite_front.agent_avatar;

    let defaultState = {
        guest_message_form_display: false,
        user_message_form_display: false,
        add_message_btn: false,
        enable_help_articles_on_home: hive_lite_front.home_tab
            ? JSON.parse(hive_lite_front.home_tab).enable_article_list
            : "",
        is_guest_chat:
            hive_lite_front.messaging_tab &&
            JSON.parse(hive_lite_front.messaging_tab.replace(/\\+/g, ""))
                .enable_guest_chat &&
            hive_lite_front.current_user == 0,
    };

    let active_user_id = 0;
    if (
        hive_lite_front.current_user != "0" ||
        hive_lite_front.current_user != 0
    ) {
        // Handle logged in user
        active_user_id = hive_lite_front.current_user;
        if (homeTabSettings.enable_users_chat_section) {
            defaultState = {
                ...defaultState,
                user_message_form_display: false,
            };
            defaultState = { ...defaultState, add_message_btn: true };
            if (homeTabSettings.users_section_type == "user_chat_form") {
                defaultState = {
                    ...defaultState,
                    user_message_form_display: true,
                    add_message_btn: false,
                };
            }
        }
    } else {
        //Handle Guest
        if (hive_lite_front.hs_guest != "") {
            active_user_id = `-${hive_lite_front.hs_guest}`;
        }
        if (homeTabSettings.enable_guest_chat) {
            defaultState = {
                ...defaultState,
                guest_message_form_display: true,
            };
        }
    }
    if (homeTabSettings.enable_guest_chat) {
        if (
            !homeTabSettings.enable_guest_chat &&
            homeTabSettings.enable_users_chat_section
        ) {
            defaultState = {
                ...defaultState,
                guest_message_form_display: false,
            };
        }
        if (homeTabSettings.guest_section_type == "guest_chat_button") {
            defaultState = {
                ...defaultState,
                guest_message_form_display: false,
                add_message_btn: true,
            };
        }
    }

    let defaultFormStatus = active_user_id == 0 ? true : false;
    defaultFormStatus = false;

    let defaultAddMessageBtnStatus = active_user_id == 0 ? false : true;
    defaultAddMessageBtnStatus = true;

    const hiveBotLocalKey = "hive_lite_bot_chats";

    // #region Preview States
    const [preview, setPreview] = useState(false);

    // home, messages, help, news
    const [tab, setTab] = useState("ai"); // home, messages, help, news, ai
    const [intro, setIntro] = useState(true);
    const [form, setForm] = useState(defaultState.guest_message_form_display);
    const [guestForm, setGuestForm] = useState(
        defaultState.guest_message_form_display
    );
    // User Message Form
    const [messageForm, setMessageForm] = useState(
        defaultState.user_message_form_display
    ); // User Message Form
    const [addMessageBtn, setAddMessageBtn] = useState(
        defaultState.add_message_btn
    );

    const [faq, setFaq] = useState(false);
    const [team, setTeam] = useState(true);
    const [conversation, setConversation] = useState(false);

    const [aiMessageForm, setAiMessageForm] = useState(false);
    const [backBtn, setBackBtn] = useState(false);
    const [messageList, setMessageList] = useState(false);
    const [activeMessage, setActiveMessage] = useState(0);

    const [articleSearchForm, setArticleSearchForm] = useState(
        defaultState.enable_help_articles_on_home
    );
    const [articleList, setArticleList] = useState(false);
    const [headTitleDisplay, setHeadTitleDisplay] = useState(true);

    let defaultFilter = {
        status: "",
        agent_id: "",
        customer_id: active_user_id,
        secret_content: hive_lite_front.hs_guest,
        count: "10",
        current_page: "1",
    };

    let chat_API_URL = `${hive_lite_front.site_url}/wp-json/hivesupport/v1/chat`;
    const AiChatBotName = "Hive Bot";

    const [filter, setFilter] = useState(defaultFilter);

    // handle in later release
    const [chatCloseBtn, setChatCloseBtn] = useState(false);

    const replyCount = useRef(hive_lite_front.total_reply_count);
    const lasActiveId = useRef(0);

    const [activeMessageID, setActiveMessageID] = useState(0);

    const [aiconversations, setAiConversations] = useState([]);

    const [headTitle, setHeadTitle] = useState("");
    // const hasNewMessage = useRef(false);

    const handleGoToAi = () => {
        setTab("ai");
        setHeadTitle(
            aiTabSettings.ai_top_bar_title
                ? aiTabSettings.ai_top_bar_title
                : "AI Chat"
        );
        setPreview(false);
        setConversation(true);
        setIntro(false);
        setGuestForm(false);
        setForm(false);
        setFaq(false);
        setTeam(false);
        setMessageForm(false);
        setBackBtn(false);
        setChatCloseBtn(false);
        setMessageList(false);
        setActiveMessage(0);
        setArticleSearchForm(false);
        setArticleList(false);
        // setAddMessageBtn(true);
        setAddMessageBtn(false);
        setAiMessageForm(true);
    };

    const handlePreview = () => {
        setPreview(false);
    };
    const handleOpenPreview = () => {
        setPreview(!preview);
    };
    
    //
    useEffect(() => {
        setTab('ai');
        handleGoToAi();
    }, []);

    const handleBackToTopLevel = () => {
        // setTab('home');
        setConversations([]);
        setActiveMessageID(0);
        setPreview(true);
        setIntro(false);
        setForm(false);
        setFaq(false);
        setTeam(false);
        setConversation(false);
        setMessageForm(false);
        setBackBtn(false);
        setChatCloseBtn(false);
        setMessageList(true);
        setActiveMessage(0);
        setAddMessageBtn(true);
    };

    const isHiveStaffAgent = (id, staffs) => {
        let isAgent = false;
        for (let key in staffs) {
            if (staffs.hasOwnProperty(key) && key == id) {
                isAgent = true;
                return isAgent;
            }
        }
        return isAgent;
    };

    function customDateFormat(inputDate) {
        const now = moment();
        const date = moment(inputDate);

        if (now.diff(date, "hours") < 1) {
            return moment(inputDate).format("mm [minute(s) ago]");
        } else if (now.diff(date, "days") < 1) {
            return `${moment(inputDate).format("HH:mm")}`;
        } else if (now.diff(date, "days") === 1) {
            return `Yesterday ${moment(inputDate).format("HH:mm")}`;
        } else {
            return moment(inputDate).format("MMMM Do YYYY");
        }
    }

    const fetchSingleChatDataUpdateWrapper = () => {
        fetchSingleChatDataUpdate();
    };

    const handleUpdateConversationUpdate = (getConversation, staffs) => {
        let newConversations = [];
        getConversation.map((conversation, index) => {
            let newConv = conversation;
            let isStaff = true;
            isStaff = isHiveStaffAgent(conversation.person_id, staffs);
            if (isStaff) {
                newConv.person_type = "agent";
            } else {
                newConv.person_type = "customer";
            }
            let thisTime = customDateFormat(conversation.created_at);
            newConv.time = thisTime;
            newConversations.push(newConv);
        });

        setHasNewMessage(true);
        setConversations(newConversations);
    };

    // Main function to fetch single chat -- single_chat
    const fetchSingleChatDataUpdate = async () => {
        let theActiveId;

        if (activeMessageID == 0) {
            theActiveId = lasActiveId.current;
            // setConversations([]);
            // return;
        } else {
            theActiveId = activeMessageID;
        }

        const request = await fetch(
            `${hive_lite_front.site_url}/wp-json/hivesupport/v1/chat/${theActiveId}`
        );
        const response = await request.json();
        if (response.success) {
            handleUpdateConversationUpdate(
                response.data.message_replies,
                response.data.agents_admins
            );
        }
    };

    const handleUpdateConversation = (getConversation, staffs) => {
        
        let newConversations = [];
        getConversation.map((conversation, index) => {
            let newConv = conversation;

            let isStaff = true;

            isStaff = isHiveStaffAgent(conversation.person_id, staffs);

            if (isStaff) {
                newConv.person_type = "agent";
            } else {
                newConv.person_type = "customer";
            }

            let thisTime = customDateFormat(conversation.created_at);
            newConv.time = thisTime;

            newConversations.push(newConv);
        });

        hiveMessageCount = newConversations.length;

        setConversations(newConversations);
    };

    // Main function to fetch single chat -- single_chat
    const fetchSingleChatData = async () => {
        
        if (activeMessageID == 0) {
            return;
        }

        const request = await fetch(
            `${hive_lite_front.site_url}/wp-json/hivesupport/v1/chat/${activeMessageID}`
        );
        const response = await request.json();
        if (response.success) {
            
            handleUpdateConversation(
                response.data.message_replies,
                response.data.agents_admins
            );
        }
    };

    const getSingleChat = () => {
        fetchSingleChatData();
    };

    const handleViewConversation = () => {
        getSingleChat();
    };

    const ChatBoxHeader = () => {
        return (
            <div className="chatContact__contents__header">
                <div className="chatContact__contents__header__close w-100">
                    <div className="ml--40">
                        {backBtn && (
                            <div
                                className="rotate--180 hs-back-button cursor--pointer"
                                onClick={handleBackToTopLevel}
                            >
                                <BackIconTop />
                            </div>
                        )}
                    </div>
                    {headTitleDisplay && (
                        <div className="chatContact__contents__head__title text-white">
                            {headTitle}
                        </div>
                    )}
                    <div className="close--icon">
                        <p
                            className="chatContact__contents__header__close__icon close_chat"
                            onClick={handlePreview}
                        >
                            <i className="material-symbols-outlined">
                                {__("remove", "hive-support")}
                            </i>
                        </p>
                        {chatCloseBtn && (
                            <p className="chatContact__contents__header__close__icon close_chat">
                                <i className="material-symbols-outlined">
                                    {__("close", "hive-support")}
                                </i>
                            </p>
                        )}
                    </div>
                </div>
            </div>
        );
    };
    
    function hsFormatDate(inputDate) {
        const date = new Date(inputDate);

        // Check if the date is valid
        if (isNaN(date.getDate())) {
            return inputDate; // Return empty string if not a valid date
        }

        // Get month, day, and year
        const month = date.toLocaleString("default", { month: "short" });
        const day = date.getDate();
        const year = date.getFullYear();

        // Format the date as desired
        const formattedDate = `${month} ${day} ${year}`;

        return formattedDate;
    }
    const OwnChat = ({ singleMessage }) => {
        const messageContent = singleMessage.content;
        const time = hsFormatDate(singleMessage.time);
        return (
            <div
                className="chatContact__contents__inner__chat__item hs_own_chat_box_front"
                data-time={time}
            >
                <div className="chatReply_content_inner">
                    <p className="chatContact__contents__inner__chat__item__para hs_front_chat__message">
                        {" "}
                        {messageContent}
                    </p>
                    {time && (
                        <p className="chatContact__contents__inner__chat__item__para hs_front_chat__name">
                            {" "}
                            {time && `${time}`}
                        </p>
                    )}
                </div>
            </div>
        );
    };
    const OthersChat = ({ singleMessage, image = defaultAvatar }) => {
        const name = singleMessage.person_name;
        const time = hsFormatDate(singleMessage.time);
        const messageContent = singleMessage.content;

        return (
            <div
                className="chatContact__contents__inner__chat__item chatReply hs_front_chat_reply_agent"
                data-name={name}
                data-time={time}
            >
                <span className="chatReply__img">
                    <svg width="37" height="36" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="18.5" cy="18" r="18" fill="#5F52DF"/>
                    <path d="M16.3063 8.54363L16.3064 7.91314C16.4035 7.83674 16.5099 7.77148 16.6474 7.71134C16.9543 7.57704 17.4964 7.43757 18.4999 7.43757C19.5035 7.43757 20.0455 7.57705 20.3525 7.71135C20.4899 7.77149 20.5963 7.83674 20.6934 7.91313V8.54356H22.887V6.99298L22.5818 6.67208C22.3159 6.39241 21.9191 5.9856 21.2256 5.68215C20.5548 5.38867 19.6899 5.22559 18.4999 5.22559C17.31 5.22559 16.445 5.38866 15.7743 5.68213C15.0807 5.98557 14.684 6.39238 14.418 6.67211L14.1129 6.99297L14.1128 8.54349L16.3063 8.54363Z" fill="white"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.9449 10.7555H22.0548C22.9377 10.7555 23.6664 10.7555 24.2599 10.8044C24.8764 10.8552 25.4431 10.9642 25.9754 11.2377C26.8009 11.6619 27.4721 12.3386 27.8927 13.1711C28.1639 13.7078 28.272 14.2793 28.3224 14.901C28.3709 15.4995 28.3709 16.2343 28.3708 17.1246V22.0823C28.3709 22.9726 28.3709 23.7074 28.3224 24.3059C28.272 24.9276 28.1639 25.4991 27.8927 26.0359C27.4721 26.8683 26.8009 27.5451 25.9754 27.9692C25.4431 28.2427 24.8764 28.3517 24.2599 28.4025C23.6664 28.4514 22.9378 28.4514 22.055 28.4514H14.9449C14.0621 28.4514 13.3333 28.4514 12.7398 28.4025C12.1233 28.3517 11.5566 28.2427 11.0243 27.9692C10.1988 27.5451 9.52768 26.8683 9.10707 26.0359C8.83585 25.4991 8.72775 24.9276 8.67738 24.3059C8.62888 23.7074 8.62889 22.9726 8.62891 22.0824V17.1246C8.62889 16.2343 8.62888 15.4995 8.67738 14.901C8.72775 14.2793 8.83585 13.7078 9.10707 13.1711C9.52768 12.3386 10.1988 11.6619 11.0243 11.2377C11.5566 10.9642 12.1233 10.8552 12.7398 10.8044C13.3334 10.7555 14.062 10.7555 14.9449 10.7555ZM18.5005 24.0274C17.1944 24.0281 15.8604 23.6901 14.5515 22.7002L15.8676 20.9307C16.7523 21.5997 17.6099 21.8159 18.4993 21.8154C19.3887 21.815 20.2482 21.5992 21.1322 20.9307L22.4483 22.7002C21.1387 23.6907 19.8028 24.0267 18.5005 24.0274ZM14.6612 16.2855C13.7526 16.2855 13.016 17.0282 13.016 17.9445C13.016 18.8607 13.7526 19.6035 14.6612 19.6035C15.5698 19.6035 16.3063 18.8607 16.3063 17.9445C16.3063 17.0282 15.5698 16.2855 14.6612 16.2855ZM20.6934 17.9445C20.6934 17.0282 21.43 16.2855 22.3386 16.2855C23.2472 16.2855 23.9837 17.0282 23.9837 17.9445C23.9837 18.8607 23.2472 19.6035 22.3386 19.6035C21.43 19.6035 20.6934 18.8607 20.6934 17.9445Z" fill="white"/>
                    </svg>
                </span>
                <div className="chatReply_content_inner">
                    <p className="chatContact__contents__inner__chat__item__para hs_front_chat__message">
                        {" "}
                        {messageContent == 'thinking' ? <span className="ai-thinking-loader"></span> : <ReactMarkdown>{messageContent}</ReactMarkdown>}
                    </p>
                    <p className="chatContact__contents__inner__chat__item__para hs_front_chat__name">
                        {" "}
                        {name} {time && `(${time})`}
                    </p>
                </div>
            </div>
        );
    };

    const ConversationsAi = () => {
        // if (!conversation) return;
        if (!(Array.isArray(aiconversations) && aiconversations.length > 0))
            return;

        // Make chat to display from bottom in recent conversations
        const chatContainerRef = useRef(null); // useRef

        useEffect(() => {
            // Scroll to the bottom when messages change
            
            if (chatContainerRef.current) {
                chatContainerRef.current.scrollTop =
                    chatContainerRef.current.scrollHeight;
            }
        }, [aiconversations]);

        return (
            
            <div
                className="chatContact__contents__inner__chat "
                id="chatContact__chat"
                ref={chatContainerRef}
            >
                <div className="chatContact__contents__inner__chat_box">
                    {aiconversations.map((singleMessage, index) => (
                        <React.Fragment key={index}>
                            {singleMessage.person_type === "customer" ? (
                                <OwnChat singleMessage={singleMessage} />
                            ) : (
                                <OthersChat singleMessage={singleMessage} />
                            )}
                        </React.Fragment>
                    ))}
                </div>
            </div>
        );
    };


    const ChatBoxAiContent = () => {
        if (!(conversation || faq)) return;
        return (
            <div
                className="chatContact__contents__inner"
                id="chatContact__inner"
            >
                <ConversationsAi />
            </div>
        );
    };
    // #endregion Component

    const BackIconTop = () => {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 16 16"
                fill="none"
            >
                <path
                    d="M5.42773 4.70898C5.46387 4.85254 5.53809 4.98828 5.65039 5.10059L8.54932 8L5.64893 10.9004C5.31689 11.2324 5.31689 11.7705 5.64893 12.1025C5.98096 12.4336 6.51904 12.4336 6.85107 12.1025L10.3516 8.60059C10.5591 8.39355 10.6367 8.10449 10.585 7.83691C10.5537 7.67578 10.4761 7.52246 10.3516 7.39844L6.85254 3.89941C6.52051 3.56738 5.98242 3.56738 5.65039 3.89941C5.43066 4.11816 5.35645 4.42871 5.42773 4.70898Z"
                    fill="#fff"
                ></path>
            </svg>
        );
    };
    
    const AIMessageForm = () => {
        const [message, setMessage] = useState("");
        const handleSetMessage = (message) => {
            setMessage(message);
        };
        let currTime = new Date();
        let time = currTime.getHours() + ":" + currTime.getMinutes();
        let localChats = localStorage.getItem(hiveBotLocalKey);
        const AiSettingsErrorMessage = "Ai chat error! Please try again later";

        let prevAiChat;
        if (localChats) {
            prevAiChat = JSON.parse(localChats);
            // setConversations(prevAiChat);
        } else {
            localStorage.setItem(hiveBotLocalKey, JSON.stringify([]));
            prevAiChat = [];
        }

        const addNewChat = async (e) => {
            e.preventDefault();
            
            // const currTime = new Date();
            const formdata = new FormData();
            formdata.append("action", "hs_ai_chat_send_message");
            formdata.append("chat_title", message);
            formdata.append("my_text", "Hye, Cute pie!");

            // Add the user Message to the list first

            const chatItem = {
                id: 0,
                message_id: 0,
                person_id: 0,
                content: message,
                media_urls: "",
                created_at: time,
                person_name: "Me",
                person_type: "customer",
                time: time,
            };
            prevAiChat = [...prevAiChat, chatItem];
            setAiConversations(prevAiChat);

            if (hive_lite_front.open_ai_ok == "no") {
                const AiChatItemError = {
                    id: 0,
                    message_id: 0,
                    person_id: 0,
                    content: AiSettingsErrorMessage,
                    media_urls: "",
                    created_at: time,
                    person_name: AiChatBotName,
                    person_type: "agent",
                    time: time,
                };
                prevAiChat = [...prevAiChat, AiChatItemError];
                setAiConversations(prevAiChat);
                return;
            }

            const AiChatItem = {
                id: 0,
                message_id: 0,
                person_id: 0,
                content: "thinking",
                media_urls: "",
                created_at: time,
                person_name: AiChatBotName,
                person_type: "agent",
                time: time,
            };
            prevAiChat = [...prevAiChat, AiChatItem];
            setAiConversations(prevAiChat);

            try {
                const request = await fetch(hive_lite_front.ajaxurl, {
                    method: "POST",
                    body: formdata,
                });

                const response = await request.json();
                
                if (response.success) {
                    
                    const aiResponse = JSON.parse(response.data.response);
                    const AiMessage = aiResponse.message?.content;
                    
                    const AiChatItem = {
                        id: 0,
                        message_id: 0,
                        person_id: 0,
                        content: AiMessage,
                        media_urls: "",
                        created_at: time,
                        person_name: AiChatBotName,
                        person_type: "agent",
                        time: time,
                    };
                    const updatedPrevAiChat = prevAiChat.slice(0, -1);
                    prevAiChat = [...updatedPrevAiChat, AiChatItem];
                    setMessage("");
                    setAiConversations(prevAiChat);

                    localStorage.setItem(
                        hiveBotLocalKey,
                        JSON.stringify(prevAiChat)
                    );
                }



            } catch (err) {
                
            }
        };

        const handleKeyDown = (e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault(); // Prevents a new line in a textarea
              addNewChat(e);
            }
          };

        useEffect(() => {
            if (prevAiChat.length > 0 && GetAiInit == 1) {
                //setConversations(prevAiChat);
                setAiConversations(prevAiChat);
                GetAiInit++;
            }
        }, []);

        return (
            <div
                className="chatContact__contents__footer chatContact__contents__footer_new"
                id="chatContact__footer_ai"
            >
                <div className="chatContact__contents__footer__input uploadImage">
                    <textarea
                        className="emoji_show"
                        name="message_ai"
                        cols="100"
                        rows="5"
                        placeholder={
                            aiTabSettings.form_placeholder
                                ? aiTabSettings.form_placeholder
                                : __("Enter Message", "hive-support")
                        }
                        value={message}
                        onChange={(e) => handleSetMessage(e.target.value)}
                        onKeyDown={handleKeyDown}
                    ></textarea>
                </div>
                <div className="chatContact__contents__footer__bottom">
                    <div className="chatContact__contents__footer__bottom__left"></div>
                    <div className="chatContact__contents__footer__bottom__right">
                        <a
                            href="javascript:void(0)"
                            className="chatSumbit hs_chat_submit_btn"
                            onClick={(e) => addNewChat(e)}
                        >
                            <i className="material-symbols-outlined">
                                {__("send", "hive-support")}
                            </i>
                        </a>
                    </div>
                </div>
            </div>
        );
    };

    const NavTab = () => {
       
        return (
            <div className="chatContact__nav_tab">
                <div className="chatContact__nav_tab_wrapper">
                    <div className="row text-center">
                        <a href="https://gethivesupport.com/" target="_blank"><p>{__(  "Powered by Hive Support", "hive-support" )}</p></a>
                    </div>
                </div>
            </div>
        );
    };

    const isMsg =
        (enableMessaging && hive_lite_front.current_user > 0) ||
        (enableMessaging && defaultState.is_guest_chat);

    let hsReplyCountSync = isMsg;

    if (hsReplyCountSync) {
        useEffect(() => {
            const intervalId = setInterval(() => {
               
            }, hsSyncingTimer);
            return () => clearInterval(intervalId);
        }, []);
    }
    

    const ChatWindow = () => {

        return (
            <div
                className={`chatContact__contents showChat`}
                id="chatContact__contents"
            >
                <div>
                    <ChatBoxHeader />
                    {/* {team && <Team />}

                    {intro && <Intro />} */}
                </div>
               
                { <ChatBoxAiContent />}
                { <AIMessageForm />}
                <NavTab />
            </div>
        );
    };

    const ChatBtn = () => {
        const ChatIcon = () => {
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="30"
                    viewBox="0 -960 960 960"
                    width="30"
                    fill="#fff"
                >
                    <path d="M240-400h320v-80H240v80Zm0-120h480v-80H240v80Zm0-120h480v-80H240v80ZM80-80v-720q0-33 23.5-56.5T160-880h640q33 0 56.5 23.5T880-800v480q0 33-23.5 56.5T800-240H240L80-80Zm126-240h594v-480H160v525l46-45Zm-46 0v-480 480Z" />
                </svg>
            );
        };
        const CloseIcon = () => {
            return (
                <svg
                    width="30"
                    height="30"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M18.601 8.39897C18.269 8.06702 17.7309 8.06702 17.3989 8.39897L12 13.7979L6.60099 8.39897C6.26904 8.06702 5.73086 8.06702 5.39891 8.39897C5.06696 8.73091 5.06696 9.2691 5.39891 9.60105L11.3989 15.601C11.7309 15.933 12.269 15.933 12.601 15.601L18.601 9.60105C18.9329 9.2691 18.9329 8.73091 18.601 8.39897Z"
                        fill="white"
                    ></path>
                </svg>
            );
        };
        return (
            <div className={`chatContact__btnWrapper mt-3`}>
                <div
                    className="chatContact__btn hs-chat-icon--wrapper"
                    onClick={handleOpenPreview}
                >
                    {" "}
                    {preview ? <CloseIcon /> : <ChatIcon />}{" "}
                </div>
            </div>
        );
    };

    // Actions after setting active single chat
    useEffect(() => {
        handleViewConversation();
    }, [activeMessageID]);

    return (
        <>
            <div className="chatContact" id="chatContact">
                {preview ? <ChatWindow /> : ""}
                <ChatBtn />
            </div>
        </>
    );
};

export default ChatBox;
